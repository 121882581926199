<template>
    <aside class="js-side-nav side-nav side-nav--visible">
        <nav class="js-side-nav__container side-nav__container">
            <header class="side-nav__header">
                 <div class="side-nav__header-logo-wrapper clearfix show-for-medium">
                    <i class="js-header__sidenav-close ti-menu side-nav__header-icon"></i>
                    <a href="/" class="side-nav__header-logo-link"><img class="side-nav__header-logo" src="/assets/img/logos/logo-full-color.png" /></a>
                </div>
                <div class="show-for-small-only">
                    <div class="side-nav__header-title">
                        <img class="side-nav__header-title-logo" src="/assets/img/logos/at-logo-icon.png" />
                        <span class="side-nav__header-title-text">Africa's Talking</span>
                    </div>
                    <div class="side-nav__header-content">
                        <span class="side-nav__user"><i class="ti-user"></i>{{user.firstName}} {{user.lastName}}</span>
                        <span class="side-nav__balance" v-if="isSandbox">
                            <i class="ti-mobile"></i>Sandbox
                        </span>
                        <span class="side-nav__balance" v-else v-on:click.prevent="refreshBalance">
                            <i class="ti-wallet"></i>Wallet: <b>{{ walletBalance.currency  }} {{ walletBalance.amount }}</b><br />
                            <i class="ti-agenda"></i>Credit: <b>{{ creditBalance.currency  }} {{ creditBalance.amount }}</b><br />
                            <i class="ti-credit-card"></i>Billing Plan: <b>{{ billingPlan  }}</b>
                        </span>
                    </div>
                </div>
            </header>
            
            <ul class="side-nav__content">
                <li>
                    <a v-on:click.prevent id="nav-dashboard" class="side-nav__menu-item no-more">
                        <router-link :to="{name:'dashboard', params: { appId: appId }}" tag="div">
                            <i class="ti-layout"></i>Dashboard
                        </router-link>
                    </a>
                </li>
                <li>
                    <a href="#" id="nav-sms" class="side-nav__menu-item more"><i class="ti-comment-alt"></i>SMS</a>
                    <ul class="side-nav__content level-1">
                        <li v-if="!isSandbox && productRequestsSupported">
                            <router-link :to="{ name: 'create-product-request', params: { appId: appId }}" class="side-nav__menu-item">Request</router-link>
                        </li>

                        <li>
                            <router-link :to="{ name: 'sms-inbox', params: { appId: appId } }" class="side-nav__menu-item">Inbox</router-link>
                        </li>

                        <li>
                            <a href="#" class="side-nav__menu-item more" id="nav-sms-bulk">Bulk SMS</a>
                            <ul class="side-nav__content level-2">
                                <li>
                                    <router-link :to="{ name: 'sms-bulk-outbox', params: { appId: appId } }" class="side-nav__menu-item">
                                        Outbox
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'sms-bulk-analytics', params: { appId: appId } }" class="side-nav__menu-item">
                                        Analytics
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#" class="side-nav__menu-item more" id="nav-sms-premium">Premium SMS</a>
                            <ul class="side-nav__content level-2">
                                <li>
                                    <router-link :to="{ name: 'sms-premium-outbox', params: { appId: appId } }" class="side-nav__menu-item">
                                        Outbox
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'sms-premium-subscriptions', params: { appId: appId } }" class="side-nav__menu-item">
                                        Subscription Products
                                    </router-link>
                                </li>
                                <li v-if="isSandbox">
                                    <router-link :to="{ name: 'sms-premium-create', params: { appId: appId } }" class="side-nav__menu-item">Create
                                        Subscription Product
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'sms-premium-analytics', params: { appId: appId } }" class="side-nav__menu-item">
                                        Analytics
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        
                        <li>
                            <a href="#" class="side-nav__menu-item more" id="nav-sms-shortcodes">Shortcodes</a>
                            <ul class="side-nav__content level-2">
                                <li>
                                    <router-link :to="{ name: 'sms-shortcodes', params: { appId: appId } }" class="side-nav__menu-item">
                                        My Shortcodes
                                    </router-link>
                                </li>
                                <li>
                                    <router-link
                                        v-if="isSandbox"
                                        :to="{ name: 'sms-shortcodes-create', params: { appId: appId } }"
                                        class="side-nav__menu-item">
                                        Create Shortcode
                                    </router-link>
                                    <router-link
                                        v-if="!isSandbox && !productRequestsSupported"
                                        :to="{ name: 'sms-shortcodes-request', params: { appId: appId } }"
                                        class="side-nav__menu-item">
                                        Request
                                    </router-link>
                                    <router-link
                                        v-if="!isSandbox && productRequestsSupported"
                                        :to="{ name: 'create-product-request', params: { appId: appId }}"
                                        class="side-nav__menu-item">
                                        Request
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#" class="side-nav__menu-item more" id="nav-sms-alpha">Alphanumerics</a>
                            <ul class="side-nav__content level-2">
                                <li>
                                    <router-link :to="{ name: 'sms-alpha', params: { appId: appId } }" class="side-nav__menu-item">
                                        My Alphanumerics
                                    </router-link>
                                </li>
                                <li>
                                    <router-link
                                        v-if="isSandbox"
                                        :to="{ name: 'sms-alpha-create', params: { appId: appId } }"
                                        class="side-nav__menu-item">
                                        Create Alphanumeric
                                    </router-link>
                                    <router-link
                                        v-if="!isSandbox && !productRequestsSupported"
                                        :to="{ name: 'sms-alpha-request', params: { appId: appId } }"
                                        class="side-nav__menu-item">
                                        Request
                                    </router-link>
                                    <router-link
                                        v-if="!isSandbox && productRequestsSupported"
                                        :to="{ name: 'create-product-request', params: { appId: appId }}"
                                        class="side-nav__menu-item">
                                        Request
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li v-if="!isSandbox">
                            <a href="#" class="side-nav__menu-item more" id="nav-sms-keywords">Keywords</a>
                            <ul class="side-nav__content level-2">
                                <li>
                                    <router-link :to="{ name: 'sms-keywords', params: { appId: appId } }" class="side-nav__menu-item">
                                        My Keywords
                                    </router-link>
                                </li>
                                <li>
                                    <router-link
                                        :to="{ name: 'sms-keywords-request', params: { appId: appId } }"
                                        class="side-nav__menu-item">
                                        Request
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li v-if="!isSandbox">
                            <router-link :to="{ name: 'sms-smpp-accounts', params: { appId: appId } }" class="side-nav__menu-item">SMPP Accounts
                            </router-link>
                        </li>
                        <li>
                            <a href="#" class="side-nav__menu-item more" id="nav-sms-callback">Callback URLs</a>
                            <ul class="side-nav__content level-2">
                                <li>
                                    <router-link :to="{ name: 'sms-dlr-callback', params: { appId: appId } }" class="side-nav__menu-item">Delivery
                                        Reports
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{ name: 'sms-inbox-callback', params: { appId: appId } }" class="side-nav__menu-item">
                                        Incoming Messages
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{ name: 'sms-bulk-callback', params: { appId: appId } }" class="side-nav__menu-item">Bulk
                                        SMS Opt Out
                                    </router-link>
                                </li>
                                
                                <li>
                                    <router-link :to="{ name: 'sms-premium-callback', params: { appId: appId } }" class="side-nav__menu-item">
                                        Subscription Notifications
                                    </router-link>
                                </li>
                                
                            </ul>
                        </li>
                    </ul>
                </li>
                <li v-if="!isSandbox && isBetaApp()">
                    <a href="#" id="nav-chat" class="side-nav__menu-item more"><i class="ti-comments"></i>Chat <span class="badge success">BETA</span></a>
                    <ul class="side-nav__content level-1">
                        <li>
                            <router-link
                                :to="{ name:'chat-products', params: { appId: appId } }"
                                class="side-nav__menu-item">
                                Products
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                v-if="!isSandbox"
                                :to="{ name: 'create-product-request', params: { appId: appId } }"
                                class="side-nav__menu-item">
                                Request
                            </router-link>
                        </li>
                        <li>
                            <router-link class="side-nav__menu-item" :to="{ name:'chat-messages', params: { appId: appId } }">
                                Messages
                            </router-link>
                        </li>
                        <li>
                            <router-link class="side-nav__menu-item" :to="{ name:'chat-analytics', params: { appId: appId } }">
                                Analytics
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li v-if="isSandbox || isBetaApp()">
                    <a href="#" id="nav-insights" class="side-nav__menu-item more"><i class="ti-thought"></i>Insights <span class="badge success">BETA</span></a>
                    <ul class="side-nav__content level-1">
                        <li>
                            <router-link class="side-nav__menu-item" :to="{ name:'insights-transactions', params: { appId: appId } }">
                                Transactions
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                v-if="!isSandbox"
                                :to="{ name: 'insights-request', params: { appId: appId } }"
                                class="side-nav__menu-item">
                                Request
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name:'insights-analytics', params: { appId: appId } }" class="side-nav__menu-item">
                                Analytics
                            </router-link>
                        </li>
                        <li>
                            <a href="#" class="side-nav__menu-item more" id="nav-insights-callback">Callback URLs</a>
                            <ul class="side-nav__content level-2">
                                <li>
                                    <router-link :to="{ name:'insights-callback', params: { appId: appId } }" class="side-nav__menu-item">
                                        Status
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <a v-if="!isSandbox" href="#" id="nav-voice" class="side-nav__menu-item more"><i class="ti-announcement"></i>Voice</a>
                    <ul class="side-nav__content level-1">
                        <li>
                            <router-link
                                :to="{ name: 'voice-phones', params: { appId: appId } }"
                                class="side-nav__menu-item">
                                Phone Numbers
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                v-if="isSandbox"
                                :to="{ name: 'sandbox-voice-create-phone', params: { appId: appId } }"
                                class="side-nav__menu-item">
                                Create Number
                            </router-link>
                            <router-link
                                v-else
                                :to="{ name: productRequestsSupported ? 'create-product-request' : 'voice-request', params: { appId: appId } }"
                                class="side-nav__menu-item">
                                Request
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'voice-sessions', params: { appId: appId } }" class="side-nav__menu-item">Sessions</router-link>
                        </li>
                        <li>
                            <router-link class="side-nav__menu-item" :to="{ name: 'voice-analytics', params: { appId: appId } }">
                                Analytics
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#" id="nav-ussd" class="side-nav__menu-item more"><i class="ti-list"></i>USSD</a>
                    <ul class="side-nav__content level-1">
                        <li>
                            <router-link
                                :to="{ name:'ussd-codes', params: { appId: appId } }"
                                class="side-nav__menu-item">
                                Service Codes
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                v-if="isSandbox"
                                :to="{ name:'sandbox-ussd-create', params: { appId: appId } }"
                                class="side-nav__menu-item">
                                Create Channel
                            </router-link>
                            <router-link
                                v-if="!isSandbox"
                                :to="{ name: productRequestsSupported ? 'create-product-request' : 'ussd-request', params: { appId: appId } }"
                                class="side-nav__menu-item">
                                Request
                            </router-link>
                        </li>
                        <li>
                            <router-link class="side-nav__menu-item" :to="{ name:'ussd-sessions', params: { appId: appId } }">
                                Sessions
                            </router-link>
                        </li>
                        <li>
                            <router-link class="side-nav__menu-item" :to="{ name:'ussd-analytics', params: { appId: appId } }">
                                Analytics
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#" id="nav-airtime" class="side-nav__menu-item more"><i class="ti-receipt"></i>Airtime</a>
                    <ul class="side-nav__content level-1">
                        <li>
                            <router-link :to="{ name:'airtime-transactions', params: { appId: appId } }" class="side-nav__menu-item">
                                Transactions
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                v-if="!isSandbox"
                                :to="{ name: productRequestsSupported ? 'create-product-request' : 'airtime-request', params: { appId: appId } }"
                                class="side-nav__menu-item">
                                Request
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name:'airtime-analytics', params: { appId: appId } }" class="side-nav__menu-item">
                                Analytics
                            </router-link>
                        </li>
                        <li>
                            <a href="#" class="side-nav__menu-item more" id="nav-airtime-callback">Callback URLs</a>
                            <ul class="side-nav__content level-2">
                                <li>
                                    <router-link :to="{ name:'airtime-callback', params: { appId: appId } }" class="side-nav__menu-item">
                                        Status
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name:'airtime-validation-callback', params: { appId: appId } }" class="side-nav__menu-item">
                                        Validation
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li v-if="!isSandbox">
                    <a href="#" id="nav-mobile-data" class="side-nav__menu-item more"><i class="ti-mobile"></i>Mobile Data</a>
                    <ul class="side-nav__content level-1">
                        <li>
                            <router-link :to="{ name:'mobile-data-products', params: { appId: appId } }" class="side-nav__menu-item">
                                Products
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                v-if="isSandbox"
                                :to="{ name: 'sandbox-mobile-data-create-product', params: { appId: appId } }"
                                class="side-nav__menu-item">
                                Create Product
                            </router-link>
                             <router-link
                                v-else
                                :to="{ name:'mobile-data-request', params: { appId: appId } }"
                                class="side-nav__menu-item">
                                Request
                            </router-link>
                        </li>

                        <li>
                            <router-link :to="{ name:'mobile-data-transactions', params: { appId: appId } }" class="side-nav__menu-item">
                                Transactions
                            </router-link>
                        </li>

                        <!-- <li v-if='isSandbox'>
                            <router-link :to="{ name: 'sandbox-payments-topup', params: { appId: appId } }" class="side-nav__menu-item">
                                Topup Wallet
                            </router-link>
                        </li> -->

                        <li>
                            <router-link :to="{ name:'mobile-data-analytics', params: { appId: appId } }" class="side-nav__menu-item">Analytics
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li v-if="!isSandbox">
                    <a href="#" id="nav-billing" class="side-nav__menu-item more"><i class="ti-credit-card"></i>Billing</a>
                    <ul class="side-nav__content level-1">
                        <li><router-link :to="{ name: 'billing-payment', params: { appId: appId }}" class="side-nav__menu-item" id="nav-billing-topup">Top Up</router-link></li>
                        
                        <li><router-link :to="{ name: 'billing-activity', params: { appId: appId }}" class="side-nav__menu-item" id="nav-billing-activity-statement">Activity Statement</router-link></li>
                        
                    </ul>
                </li>

                <li v-if="!isSandbox && productRequestsSupported">
                    <a href="#" id="nav-product-requests" class="side-nav__menu-item more">
                        <i class="ti-write"></i>Product Requests
                    </a>
                    <ul class="side-nav__content level-1">
                        <li><router-link :to="{ name: 'list-product-requests', params: { appId: appId }}" class="side-nav__menu-item">Requests</router-link></li>
                         <li><router-link :to="{ name: 'create-product-request', params: { appId: appId }}" class="side-nav__menu-item">Submit Request</router-link></li>
                    </ul>
                </li>

                <li>
                    <a id="nav-settings" class="side-nav__menu-item more" href="#"><i class="ti-settings"></i>Settings</a>
                    <ul class="side-nav__content level-1">
                        <li>
                            <router-link :to="{ name: 'settings-api', params: { appId: appId } }" class="side-nav__menu-item">
                                API Key
                            </router-link>
                        </li>
                        <li v-if='!isSandbox'>
                            <router-link :to="{ name: 'settings-balance-notification', params: { appId: appId } }" class="side-nav__menu-item">
                                Balance Alert
                            </router-link>
                            
                        </li>
                    </ul>
                </li>

                <li v-if="isSandbox">
                    <a href="https://developers.africastalking.com/simulator" class="side-nav__menu-item primary" target="_blank">
                        <i class="ti-rocket"></i>Launch Simulator
                    </a>
                </li>

                <li v-if="isSandbox">
                    <a href="https://slack.africastalking.com" class="side-nav__menu-item primary" target="_blank">
                        <i class="ti-comments-smiley"></i>Join us on Slack
                    </a>
                </li>
            </ul>
        </nav>
    </aside>
</template>

<script>
    /* global appLoading, nest */
    import {
        money,
        capitalize,
        getCurrentApp,
        getCurrentAppId,
        isSmallestViewport,
        notificationWatcher,
    } from '../../../utils';
    import {
        getUser,
        isAliasing,
        isLoggedIn,
        isProductRequestSupported,
    } from '../../../utils/api/auth';
    import { getBalance } from '../../../utils/api/app';
    import { inviteToSlack } from '../../../utils/api/user';

    export default {

        data: () => ({
            message: null,
            error: null,
            success: null,
            isSandbox: false,
            isAliasing: isAliasing(),
            appId: getCurrentAppId(),
            app: getCurrentApp(),
            user: getUser(),
            walletBalance: {
                currency: null,
                amount: money(0),
            },
            creditBalance: {
                currency: null,
                amount: money(0)
            },
            billingPlan: null,
        }),

        filters: {
            capitalize,
        },

        watch: {
            error: notificationWatcher('error', 'error'),
            message: notificationWatcher('message', 'info'),
            success: notificationWatcher('success', 'success'),
        },

        created: async function fx() {
            this.app = getCurrentApp();
            this.appId = getCurrentAppId();
            this.isSandbox = this.app && this.app.sandbox === true;

            this.loggedIn = isLoggedIn(this.isAdmin);
            if (this.loggedIn && !this.isAdmin && isSmallestViewport) {
                this.refreshBalance();
            }
        },

        methods: {
            isBetaApp() {
                return nest.betaApps.includes(this.app.username);
            },

            refreshBalance: async function refreshBalance() {
                if (!this.isSandbox) {
                    appLoading.start();
                    const balance = await getBalance();
                    if (balance) {
                        this.walletBalance.currency = balance.walletBalance.currencyCode;
                        this.walletBalance.amount = money(balance.walletBalance.amount, 2);
                        this.creditBalance.currency = balance.creditBalance.currencyCode;
                        this.creditBalance.amount = money(balance.creditBalance.amount, 2);
                        this.billingPlan = balance.currentBillingPlan;
                    }
                    appLoading.stop();
                }
            },

            openSlack: async function fx() {
                if (this.isAliasing) {
                    this.error = 'You are not allowed to perform this action';
                    return;
                }

                if (this.user.slack) {
                    window.open('https://africastalking.slack.com');
                    return;
                }

                const invited = await inviteToSlack(this.user.email);
                if (!invited) {
                    if (nest.last_kmsg) {
                        this.error = nest.last_kmsg;
                        nest.last_kmsg = null;
                    } else {
                        this.error = 'Failed send a slack invitation';
                    }
                } else {
                    this.success = `Your invitation has been sent to <b>${this.user.email}</b>. Please check your inbox.`;
                }
            },
        },
        computed: {
            productRequestsSupported() {
                return isProductRequestSupported();
            },
        },
    };
</script>
