const Payment = resolve => require(['./payment/component.vue'], resolve); // eslint-disable-line
const activityStatement = resolve => require(['./activityStatement/component.vue'], resolve); // eslint-disable-line

export default [
    {
        path: '/billing/payment/methods', name: 'billing-payment', component: Payment, meta: { subtitle: 'Top Up' }
    },
    {
        path: '/billing/activity', name: 'billing-activity', component: activityStatement, meta: { subtitle: 'Activity Statement' }
    }
]
